import { HOME_VIDEOS_API } from '../../../Utilities/APIs'
import Navbar from '../../Partials/Navbar/Navbar'
// import OverflowSc from '../../Layouts/OverflowSc/OverflowSc'
import Video from '../../Layouts/Video/Video'
import FeaturedIn from './FeaturedIn/FeaturedIn'
import HomeAbout from './HomeAbout/HomeAbout'
import HomeBanner from './HomeBanner/HomeBanner'
import Services from './Services/Services'
import Testimonials from './Testimonials/Testimonials'
import FeaturedArticle from './FeaturedArticle/FeaturedArticle'
import FeaturedSuccessStory from './FeaturedSuccessStory/FeaturedSuccessStory'
import Books from './Books/Books'

import './Home.css'

function Home() {
  return (
    <>
      <Navbar isTransparent />
      <main className="home_page">
        <HomeBanner />
        <FeaturedIn />
        <HomeAbout />
        <Video
          parentApi={HOME_VIDEOS_API}
        />
        <Services />
        <Testimonials />
        <Books />
        <FeaturedArticle />
        <FeaturedSuccessStory />
      </main>
    </>
  )
}

export default Home