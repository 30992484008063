import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios';
import iconArrow from '../../../../Assets/Icons/arrow-right-purple.svg'
import { HOME_SLIDERS_API, IMAGE_URL } from '../../../../Utilities/APIs';

import './HomeBanner.css'

function HomeBanner() {

    const [sliders, setSliders] = useState(null);

    useEffect(() => {
        async function homeSliders() {
            const { data } = await axios.get(HOME_SLIDERS_API);
            setSliders(data);
        }
        homeSliders()
    }, [])

    return (
        <section className="home_banner" style={{ backgroundImage: `url('${sliders && IMAGE_URL + sliders[0]?.image}')` }}>
            <div className="container_fluid container">
                <div className="banner_inner">
                    <div className='large_title'>
                        <h1>{sliders && sliders[0]?.title.slice(0, 9)}</h1>
                        <h1>{sliders && sliders[0]?.title.slice(9)}</h1>
                    </div>
                    <div className="list">
                        <span>{sliders && sliders[0]?.service1}</span>
                        <span>• {sliders && sliders[0]?.service2}</span>
                        <span>• {sliders && sliders[0]?.service3}</span>
                    </div>
                    <div className="text_button">
                        <p className='text'>{sliders && sliders[0]?.description}</p>
                        <div>
                            <Link to="/contact" className="btn_large bg_white">
                                <div>
                                    <span>{sliders && sliders[0]?.buttonText1}</span>
                                    <p>{sliders && sliders[0]?.buttonText2}</p>
                                </div>
                                <img src={iconArrow} alt="icon" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeBanner