import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
// import Testimonial2 from '../../Layouts/Testimonial2/Testimonial2'
import Video from "../../Layouts/Video/Video";
import Navbar from "../../Partials/Navbar/Navbar";
import AbtSlider from "./AbtSlider/AbtSlider";
import {
  ABOUT_MAINTEXT_API,
  ABOUT_VIDEOS_API,
  ABOUT_WRITE_UPS_API,
  TEXT_SLIDERS_API,
} from "../../../Utilities/APIs";
import AbtImpatcs from "./AbtImpatcs/AbtImpatcs";
import HTSlider from "./HTSlider/HTSlider";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "./About.css";
// import { useRoutes } from 'react-router-dom'

gsap.registerPlugin(ScrollTrigger);

function About() {
  const { pathname } = useLocation();

  const el = useRef();
  // const tl = useRef();
  const one = useRef();
  const two = useRef();

  const [abouts, setAbouts] = useState(null);
  const [writeUps, setWriteUps] = useState(null);
  const [textSlides, setTextSlides] = useState(null);
  const [mainText, setMainText] = useState("");

  useEffect(() => {
    async function getAbouts() {
      const { data } = await axios.get(ABOUT_VIDEOS_API);
      // const { data } = await axios.get(ABOUTS_API);
      setAbouts(data);
    }
    getAbouts();
  }, []);

  useEffect(() => {
    async function getWriteUps() {
      const { data } = await axios.get(ABOUT_WRITE_UPS_API);
      setWriteUps(data);
    }
    getWriteUps();
  }, []);

  useEffect(() => {
    async function getTextSlides() {
      const { data } = await axios.get(TEXT_SLIDERS_API);
      setTextSlides(data);
    }
    getTextSlides();
  }, []);

  useEffect(() => {
    async function getMainText() {
      const { data } = await axios.get(ABOUT_MAINTEXT_API);
      setMainText(data[0]);
    }
    getMainText();
  }, []);

  useEffect(() => {
    if (pathname === "/about") {
      ScrollTrigger.refresh();
    }
  }, [pathname]);

  // useLayoutEffect(() => {
  //     if (pathname === '/about') {
  //         const ctx = gsap.context(() => {
  //             tl.current = gsap.timeline({
  //                 scrollTrigger: {
  //                     trigger: el.current,
  //                     scrub: true,
  //                     start: "top 50%",
  //                     // end: "top 10%",
  //                 }
  //             })
  //                 .to(".one", {
  //                     x: 300,
  //                     rotation: 360,
  //                     stagger: 0.1,
  //                 })
  //         }, el);
  //         return () => ctx.revert();

  //     }

  // }, [pathname]);

  // useLayoutEffect(() => {

  // const ctx = gsap.context(() => {

  // const el1 = one.current;
  // const el2 = two.current;

  // let timeline = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: scrollRef.current,
  //       start: "top top",
  //       end: "bottom 50%",
  //       scrub: true,
  //     //   pin: true
  //     }
  //   });

  //   timeline.from(el1, {
  //     x: 100,
  //     opacity: 0.5,
  //     duration: 2,
  //     delay: 2
  //   });

  // gsap.from(el1, {
  //     x: 500,
  //     scrollTrigger: {
  //         trigger: el1,
  //         start: 'bottom bottom',
  //         end: 'top top',
  //         scrub: true,
  //     },
  // });

  // gsap.from(el2, {
  //     x: -360,
  //     scrollTrigger: {
  //         trigger: el2,
  //         start: 'bottom bottom',
  //         end: 'top top',
  //         scrub: true,
  //     },
  // });

  // }, el);
  //
  // return () => ctx.revert();

  // }, [])

  // useEffect(() => {
  //   const ctx = gsap.context(() => {
  //     const el1 = one.current;
  //     const el2 = two.current;

  //     gsap.from(el1, {
  //       x: 500,
  //       scrollTrigger: {
  //         trigger: el1,
  //         start: "bottom bottom",
  //         end: "top top",
  //         scrub: true,
  //       },
  //     });

  //     gsap.from(el2, {
  //       x: -360,
  //       scrollTrigger: {
  //         trigger: el2,
  //         start: "bottom bottom",
  //         end: "top top",
  //         scrub: true,
  //       },
  //     });
  //   }, el);

  //   return () => ctx.revert();
  // }, []);
	useEffect(() => {
		const timeout = setTimeout(() => {
			const ctx = gsap.context(() => {
				const el1 = one.current;
				const el2 = two.current;

				gsap.from(el1, {
					x: 500,
					scrollTrigger: {
						trigger: el1,
						start: 'bottom bottom',
						end: 'top top',
						scrub: true,
					},
				});

				gsap.from(el2, {
					x: -360,
					scrollTrigger: {
						trigger: el2,
						start: 'bottom bottom',
						end: 'top top',
						scrub: true,
					},
				});
			}, el);

			return () => {
				ctx.revert();
				clearTimeout(timeout);
			};
		}, 2500);
	}, []);
  return (
    <>
      <Navbar />
      <main className="about_page">
        <div className="container_fluid container">
          <h1 className="abt_page_title">{mainText?.name}</h1>
          <h1 className="abt_page_title">
            <HTSlider />
          </h1>
          {/* <h1 className="abt_page_title">in business.</h1> */}
        </div>
        <Video parentApi={ABOUT_VIDEOS_API} />
        <section className="abt_page_center_text py_10">
          <div className="container_fluid container">
            <div
              dangerouslySetInnerHTML={{
                __html: abouts && abouts[0]?.description,
              }}
            />
          </div>
        </section>

        <AbtImpatcs />

        <section className="title_text_sc py_10">
          <div className="container_fluid container">
            <div className="grid_container">
              <div className="grid_item">
                <h2>{writeUps && writeUps[0]?.title}</h2>
              </div>
              <div className="grid_item">
                <div
                  dangerouslySetInnerHTML={{
                    __html: writeUps && writeUps[0]?.description,
                  }}
                />
              </div>
            </div>
          </div>
        </section>

        <AbtSlider />

        {/* <Testimonial2 /> */}

        <section className="marque_sc py_10 mb_10" ref={el}>
          <div className="one" ref={one}>
            {textSlides?.map((item) => (
              <p key={item._id}>{item?.title}</p>
            ))}
          </div>
          <div className="two" ref={two}>
            {textSlides?.map((item) => (
              <p key={item._id}>{item?.title}</p>
            ))}
          </div>

          {/* <marquee loop="-1">
                        <div>
                            {textSlides?.map(item => (
                                <p key={item._id}>{item?.title}</p>
                            ))}
                        </div>

                    </marquee>
                    <marquee direction="right" loop="-1">
                        <div>
                            {textSlides?.map(item => (
                                <p key={item._id}>{item?.title}</p>
                            ))}
                        </div>
                    </marquee> */}
        </section>
      </main>
    </>
  );
}

export default About;
