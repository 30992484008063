import { useState, useEffect, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom'
import AppContext from '../../../AppContext/AppContext';

import hambIcon from '../../../Assets/Icons/hamb.svg'
import logoWhite from '../../../Assets/Images/logo.svg'
import logoBlack from '../../../Assets/Images/logo-black.svg'

import './Navbar.css'

function Navbar({ isTransparent }) {

    const { services } = useContext(AppContext)

    const [isNavShow, setIsNavShow] = useState(false);
    const [scroll, setScroll] = useState(false)


    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 100)
        })
    }, []);

    const handleNavToggle = () => {
        setIsNavShow(pervState => !pervState)
    }

    return (
        <section className={`navbar ${(isNavShow || scroll) && 'bg_white'} ${isTransparent && 'transparent'}`}>
            <div className="container_fluid container">
                <div className='flex_container'>
                    <Link to='/' className="logo_wrapper">
                        <img src={logoWhite} alt="brand" className='logo_white' />
                        <img src={logoBlack} alt="brand" className='logo_black' />
                    </Link>
                    <nav className={`largenav ${isNavShow && 'show'}`}>
                        <NavLink to="/about">About</NavLink>
                        {services?.map(service => (
                            <NavLink
                                key={service._id}
                                to={"/service/" + service.slug}
                            >
                                {service.shortName}
                            </NavLink>
                        ))}
                         <NavLink to="/press-and-media">Press & Media </NavLink>
                    </nav>
                    <nav className={`smallnav ${isNavShow && 'show'}`}>
                        <NavLink to="/about">About</NavLink>
                        {services?.map(service => (
                            <NavLink
                                key={service._id}
                                to={"/service/" + service.slug}
                                onClick={handleNavToggle}
                            >
                                {service.shortName}
                            </NavLink>
                        ))}
                         <NavLink to="/press-and-media">Press & Media </NavLink>
                        <Link to='/contact' className="nav_cont_btn" onClick={handleNavToggle}>
                            <span>contact</span>
                        </Link>
                    </nav>
                    <div>
                        <Link to='/contact' className="nav_cont_btn">
                            <span>contact</span>
                        </Link>
                    </div>
                    <button className='btn_menu_toggle' onClick={handleNavToggle}>
                        <img src={hambIcon} alt="icon" />
                    </button>
                </div>
            </div>
        </section>
    )
}

export default Navbar