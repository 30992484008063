import iconArrow from "../../../Assets/Icons/arrow-right-black.svg";
// import mediaImg from '../../../Assets/Images/media-img-1.png'
import { IMAGE_URL } from "../../../Utilities/APIs";

import "./MediaCard.css";

function MediaCard({ img, date, tag, name, link }) {
  return (
    <div className="media_card">
      <div className="media_card_image">
        <img src={IMAGE_URL + img} alt="thumb" className="thumb_img" />
      </div>
      <div className="content">
        <div className="date_tag">
          <span>
            {new Date(date).toLocaleDateString("en-US", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </span>
          <span>{tag}</span>
        </div>
        {/* <div
          dangerouslySetInnerHTML={{ __html: content?.description }}
        /> */}
        <h3>{name}</h3>
      </div>
      <a href={link} target="_blanck">
        <span>Read Now</span>
        <img src={iconArrow} alt="icon" />
      </a>
    </div>
  );
}

export default MediaCard;
